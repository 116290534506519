import React from 'react';
import Header from './Header';
import Footer from './Footer';

function AboutUs() {
  return (
    <>
    <Header/>
    <div style={{fontFamily: "Arial", lineHeight: "1.6", padding: "10px"}}>
    <div style={{maxWidth: "800px", margin: "auto", padding: "20px"}}>
        <h1 style={{color: "#333"}}>About Us</h1>
        <p><strong>TheatreDekho.com</strong> is a pioneering initiative aimed at revolutionizing the assamese theatre industry in Assam. Founded by two passionate and enthusiastic brothers, TheatreDekho went live in July 2024 with a vision to bring the magic of assamese theatre closer to audiences through a seamless online ticket booking platform. Our first featured theatre is Awahan Theatre also spelled Abahan Theatre.</p>
        
        <h2 style={{color: "#333"}}>Our Vision</h2>
        <p>Our mission is to construct a comprehensive ecosystem that benefits all stakeholders in the theatre industry. We aim to provide:</p>
        <ul>
            <li>Consumers with easy, anytime, anywhere access to theatre tickets booking.</li>
            <li>Theatre owners and committees with efficient management tools for theatre ticketing processes and distribution.</li>
            <li>Related businesses with new opportunities to connect with theatre-goers.</li>
        </ul>
        
        <h2 style={{color: "333"}}>What We Offer?</h2>
        <p>TheatreDekho offers a hassle-free, user-friendly online platform where users can book tickets for theatres across Assam. Prior to TheatreDekho, there was no centralized online system for booking theatre tickets in the region. We have filled this gap, providing a service that is not only convenient for consumers but also enhances operational efficiency for theatre committees.</p>
        
        <h2 style={{color: "333"}}>Our Commitment</h2>
        <p>At TheatreDekho, we are committed to:</p>
        <ul>
            <li>Ensuring a smooth and enjoyable theatre ticket booking experience for our users.</li>
            <li>Supporting theatre owners and committees with tools that simplify ticket sales and management.</li>
            <li>Expanding our reach to cover all theatres in Assam, providing a unified platform for all theatre-related activities.</li>
        </ul>
        
        <h2 style={{color: "333"}}>Our Founders</h2>
        <p>Driven by their shared love for theatre and technology, our founders have combined their skills and passion to create TheatreDekho. They believe in the transformative power of theatre and are dedicated to making it more accessible to everyone. Their enthusiasm and innovative spirit are the driving forces behind TheatreDekho's success.</p>
        
        <h2 style={{color: "333"}}>Join Us</h2>
        <p>We invite you to be a part of this exciting journey. Whether you're a theatre enthusiast looking to book tickets with ease or a theatre owner seeking better management solutions, TheatreDekho is here to serve you.</p>
        
        <p>Experience the future of theatre ticket booking with <span style={{color: "#007BFF"}}>TheatreDekho.com</span>!</p>
    </div>
    </div>
    <Footer/>
    </>
  )
}

export default AboutUs