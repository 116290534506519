import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useDataStore = create(
    persist(
        (set) => ({
            uid: '',
            name: '',
            email: '',
            mobileNumber: '',
            photo: '',
            selectedSeats: [],
            totalPrice: 0,
            globalShowType: 'blank',
            globalShowId: 'blank',
            
            setUid: (data) => set({uid: data}),
            setName: (data) => set({name: data}),
            setEmail: (data) => set({email: data}),
            setMobileNumber: (data) => set({mobileNumber: data}),
            setPhoto: (data) => set({photo: data}),
            setSelectedSeats: (seats) => set({ selectedSeats: seats }),
            setTotalPrice: (price) => set({totalPrice: price}),
            setGlobalShowType: (data) => set({globalShowType: data}),
            setGlobalShowId: (data) => set({globalShowId: data}),
        }),
        {
            name: 'food-storage', // name of the item in the storage (must be unique)
            //storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
          },
    ),
)

export default useDataStore;