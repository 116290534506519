import React, { useEffect, useState } from 'react';
import { getDocs, query, where, collection, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import './Dashboard.css'; // Add this line for custom styles

// Query function for booked seats per section
async function getTotalBookedSeatsPerSection(showType, showId) {
  const sections = {}; // Object to hold the count of booked seats per section

  // Fetch all bookings for the given showId
  const bookingsQuery = query(
    collection(db, 'theatres', 'Awahan', showType, showId, 'bookings'), where("status", "==", "booked")
  );

  const querySnapshot = await getDocs(bookingsQuery);

  querySnapshot.forEach(doc => {
    const bookingData = doc.data();
    const sectionId = bookingData.section;

    if (sections[sectionId]) {
      sections[sectionId]++;
    } else {
      sections[sectionId] = 1;
    }
  });

  return sections;
}


function Dashboard() {
  const [bookedSeatsPerSectionFirst, setBookedSeatsPerSectionFirst] = useState({});
  const [bookedSeatsPerSectionSecond, setBookedSeatsPerSectionSecond] = useState({});
  const [bookedSeatsPerSectionThird, setBookedSeatsPerSectionThird] = useState({});
  const [bookedSeats, setBookedSeats] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedShowType, setSelectedShowType] = useState('first'); // Default to 'first'
  const [selectedShowId, setSelectedShowId] = useState('first1'); // Default to 'first1'
  const [showIds, setShowIds] = useState({ first: 'first1', second: 'second1', third: 'third1' });

  useEffect(() => {
    async function fetchData() {
      const bookedSeats1 = await getTotalBookedSeatsPerSection('first', 'first1');
      const bookedSeats2 = await getTotalBookedSeatsPerSection('second', 'second1');
      const bookedSeats3 = await getTotalBookedSeatsPerSection('third', 'third1');
      setBookedSeatsPerSectionFirst(bookedSeats1);
      setBookedSeatsPerSectionSecond(bookedSeats2);
      setBookedSeatsPerSectionThird(bookedSeats3);
    }

    fetchData();
  }, []);

  // Fetch booked seats based on selected showType and showId
  useEffect(() => {
    async function fetchData() {
      if (!selectedShowType || !selectedShowId) return; // Ensure both are set
      const pathSegments = ['theatres', 'Awahan', selectedShowType, selectedShowId, 'bookings'];
      
      if (pathSegments.length % 2 === 0) {
        console.error('Invalid collection path:', pathSegments.join('/'));
        return; // Prevent querying with an invalid path
      }

      const bookingsQuery = query(
        collection(db, ...pathSegments),
        where("status", "==", "booked"),
        orderBy("at", "desc") // Sort by timestamp 'at' in descending order
      );

      const querySnapshot = await getDocs(bookingsQuery);
      const bookings = querySnapshot.docs.map(doc => {
        const data = doc.data();
        // Convert Firestore Timestamp to Date
        const date = data.at ? data.at.toDate() : null; // Convert to JavaScript Date object
        // Format the date and time to a readable format
        const formattedDate = date ? date.toLocaleString() : 'N/A';
        
        return {
          id: doc.id,
          ...data,
          formattedDate, // Add formatted date
          showType: selectedShowType,
          showId: selectedShowId,
        };
      });
      
      setBookedSeats(bookings);
    }

    fetchData();
  }, [selectedShowType, selectedShowId]); // Refetch when selection changes

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleShowTypeChange = (e) => {
    const newShowType = e.target.value;
    setSelectedShowType(newShowType);
    setSelectedShowId(showIds[newShowType] || ''); // Set default or empty if not found
  };

  const handleShowIdChange = (e) => {
    setSelectedShowId(e.target.value);
  };

  const filteredBookings = bookedSeats.filter(booking => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const queryTerms = lowerCaseQuery.split(' ');

    return queryTerms.every(term => (
      (booking.seatNumber?.toString().includes(term)) ||
      (booking.uniqueID?.toLowerCase().includes(term)) ||
      (booking.mobile?.toString().includes(term)) ||
      (booking.price?.toString().includes(term)) ||
      (booking.section?.toLowerCase().includes(term)) ||
      (booking.row?.toLowerCase().includes(term)) ||
      (booking.number?.toString().includes(term)) ||
      (booking.mode?.toLowerCase().includes(term)) ||
      (booking.showType?.toLowerCase().includes(term)) ||
      (booking.showId?.toLowerCase().includes(term)) ||
      (booking.paymentId?.toLowerCase().includes(term))
    ));
  });

  // Download filtered data as Excel file
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredBookings);
    XLSX.utils.book_append_sheet(workbook, worksheet, `Show_${selectedShowId}`);

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'bookings.xlsx');
  };

  return (
    <div className="dashboard">
      <header>
        <h1>Admin Dashboard</h1>
      </header>

      <section className="stats">
        <div className="stat-box">
          <h4>First Night Bookings</h4>
          <ul>
            {Object.entries(bookedSeatsPerSectionFirst).map(([sectionId, count]) => (
              <li key={sectionId}>
                <span>Section {sectionId}:</span> <strong>{count}</strong> seats booked
              </li>
            ))}
          </ul>
        </div>
        <div className="stat-box">
          <h4>Second Night Bookings</h4>
          <ul>
            {Object.entries(bookedSeatsPerSectionSecond).map(([sectionId, count]) => (
              <li key={sectionId}>
                <span>Section {sectionId}:</span> <strong>{count}</strong> seats booked
              </li>
            ))}
          </ul>
        </div>
        <div className="stat-box">
          <h4>Third Night Bookings</h4>
          <ul>
            {Object.entries(bookedSeatsPerSectionThird).map(([sectionId, count]) => (
              <li key={sectionId}>
                <span>Section {sectionId}:</span> <strong>{count}</strong> seats booked
              </li>
            ))}
          </ul>
        </div>
      </section>

        {/* Dropdown for Show Type */}
        <select value={selectedShowType} onChange={handleShowTypeChange}>
          <option value="first">First Night</option>
          <option value="second">Second Night</option>
          <option value="third">Third Night</option>
        </select>

        {/* Dropdown for Show ID */}
        <select value={selectedShowId} onChange={handleShowIdChange}>
          {selectedShowType === 'first' && <option value="first1">First Show (first1)</option>}
          {selectedShowType === 'second' && <option value="second1">Second Show (second1)</option>}
          {selectedShowType === 'third' && <option value="third1">Third Show (third1)</option>}
        </select>

      <section className="database">
        <div className="controls">
          <input 
            type="text" 
            placeholder="Search bookings..." 
            value={searchQuery}
            onChange={handleSearch}
            className="search-bar"
          />
          <button onClick={downloadExcel} className="download-btn">Download as Excel</button>
        </div>

        <div className="table-container">
          <table className="bookings-table">
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>Unique ID</th>
                <th>Mobile Number</th>
                <th>Price</th>
                <th>Section</th>
                <th>Row</th>
                <th>Number</th>
                <th>Mode</th>
                <th>Date & Time</th> {/* New column for Date & Time */}
                <th>Show ID</th>
                <th>Payment ID</th>
              </tr>
            </thead>
            <tbody>
              {filteredBookings.map((booking, index) => (
                <tr key={booking.id}>
                  <td>{index + 1}</td>
                  <td>{booking.uniqueID ?? 'N/A'}</td>
                  <td>{booking.mobile ?? 'N/A'}</td>
                  <td>{booking.price ?? 'N/A'}</td>
                  <td>{booking.section ?? 'N/A'}</td>
                  <td>{booking.row ?? 'N/A'}</td>
                  <td>{booking.number ?? 'N/A'}</td>
                  <td>{booking.mode ?? 'N/A'}</td>
                  <td>{booking.formattedDate ?? 'N/A'}</td> {/* Display formatted date */}
                  <td>{booking.showId ?? 'N/A'}</td>
                  <td>{booking.paymentId ?? 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}

export default Dashboard;
