import React from 'react';
import Footer from './Footer';
import { Link } from 'react-router-dom';

import Header from './Header';
import './Home.css';
import MobileMenu from './MobileMenu';

//import useDataStore from './DataStore';

//import populateSeats from './components/Data';
//import renameFieldInDocuments from '../app/components/Extra';

const theaters = [
  { id: 'Awahan', name: 'Awahan Theatre', image: "https://i.ibb.co/n6vLWmH/awahan.jpg" }//,
  //{ id: 'Kohinoor', name: 'Theatre 2', image: abahanimg1 },
  //{ id: 'Hengul', name: 'Theatre 3', image: abahanimg1 },
];

function Home() {
  //const uid = useDataStore((state) => state.uid);
  //console.log(uid);
  //populateSeats();
  return (
    <div className='container'>
    <Header />
    <div className="homepage-container">
      <div className="theaters">
        {theaters.map(theater => (
          <Link key={theater.id} to={`/${theater.id}`} className="theater-card">
            <img src={theater.image} alt={theater.name} />
            <div className="theater-name">{theater.name}</div>
            <div>Click here to Book Tickets</div>
          </Link>
        ))}
      </div>
    </div>

    <Footer />
    <MobileMenu />
    </div>
  )
}

export default Home