import React from 'react';
import { Link } from 'react-router-dom';
import profileIcon from '../images/profileIcon.png';
import theatresIcon from '../images/theatresIcon.png';
import ticketsIcon from '../images/ticketsIcon.png';
import './MobileMenu.css';

function MobileMenu() {
  return (
    <div className='menuContainer'>
        <Link to={'/'} className='sections'>
            <img className='icons' alt='icon' src={theatresIcon} />
            Theatres
        </Link>
        <Link to={'/myorders'} className='sections'>
            <img className='icons' alt='icon' src={ticketsIcon} />
            Bookings
        </Link>
        <Link to={'/profile'} className='sections'>
            <img className='icons' alt='icon' src={profileIcon} />
            Profile
        </Link>
    </div>
  )
}

export default MobileMenu
