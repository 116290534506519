import React from 'react'

function CancellationRefund() {
  return (
    <div style={{padding: "10px"}}>
        <h1>Return and Refund Policy</h1>
        <p>Last updated: July 27, 2024</p>
        <p>Thank you for booking tickets with TheatreDekho.</p>
        <p>If you have any issues or concerns regarding your booking, we invite you to review our policy on refunds and cancellations. This Return and Refund Policy has been created to ensure clarity and fairness.</p>
        <h2>Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
        <h3>Definitions</h3>
        <p>For the purposes of this Retuurn and Refund Policy:</p>
        <ul>
            <li>
                <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to TheatreDekho.</p>
            </li>
            <li>
                <p><strong>Tickets</strong> refer to the items (event tickets) offered for sale on the Service.</p>
            </li>
            <li>
                <p><strong>Orders</strong> mean a request by You to purchase Tickets from Us.</p>
            </li>
            <li>
                <p><strong>Service</strong> refers to the Website.</p>
            </li>
            <li>
                <p><strong>Website</strong> refers to TheatreDekho, accessible from <a href="http://theatredekho.com" rel="noreferrer" target="_blank">http://theatredekho.com</a></p>
            </li>
            <li>
                <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            </li>
        </ul>
        <h2>Your Ticket Cancellation Rights</h2>
        <p>Once a ticket is booked through TheatreDekho, it cannot be cancelled or refunded. We recommend ensuring that all booking details are correct before finalizing your purchase.</p>
        <h2>Assistance and Support</h2>
        <p>We strive to provide the best possible service. If you encounter any issues or have any concerns regarding your booking, please contact us for assistance. We are here to help and will do our best to resolve any problems.</p>
        <p>You can contact us by:</p>
        <ul>
            <li>By email: jpboro01@gmail.com</li>
            <li>By phone: +91 7896802376</li>
        </ul>
        <h2>Conditions for Returns</h2>
        <p>Given the nature of our service, tickets purchased through TheatreDekho are non-cancellable and non-refundable. However, we understand that exceptional circumstances can occur, and we will address each case individually to provide the best possible solution.</p>
        <h3>Contact Us</h3>
        <p>If you have any questions about our Return and Refund Policy, please contact us:</p>
        <ul>
            <li>By email: jpboro01@gmail.com</li>
        </ul>
    </div>
  )
}

export default CancellationRefund