
import './App.css';
import { Routes, Route } from 'react-router-dom';

import {AuthProvider} from './useAuth';

import Home from './app/Home';
import SignIn from './app/SignIn';
import PrivateRoute from './app/PrivateRoute';
import AboutUs from './app/AboutUs';
import SeatingArrangement from './app/components/SeatingArrangement';
import TheatrePage from './app/components/TheatrePage';
import MyOrders from './app/components/MyOrders';
import ProfilePage from './app/ProfilePage';
import PrivacyPolicy from './app/Privacypolicy';
import TermsAndConditions from './app/TermsAndConditions';
import CancellationRefund from './app/CancellationRefund';
import ContactUs from './app/ContactUs';
import Dashboard from './app/Dashboard';


function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
        <Route path="/myorders" element={<PrivateRoute><MyOrders /></PrivateRoute>} />
        <Route path="/:id/:showType/:showId" element={<PrivateRoute><SeatingArrangement /></PrivateRoute>} />
        <Route path="/:id" element={<PrivateRoute><TheatrePage /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute><ProfilePage/></PrivateRoute>} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path='/privacy_policy' element={<PrivacyPolicy />} />
        <Route path='/terms_and_conditions' element={<TermsAndConditions />} />
        <Route path='/cancellation_and_refund' element={<CancellationRefund />} />
        <Route path='/contact_us' element={<ContactUs />} />
        <Route path='/about_us' element={<AboutUs />} />
      </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
