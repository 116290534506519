import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import useDataStore from '../DataStore';
import QRCode from 'qrcode.react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import CryptoJS from 'crypto-js';
import './MyOrders.css';
import briksha from '../../images/briksha.jpg';
import MobileMenu from '../MobileMenu';
//import dotenv from 'dotenv';

// Load environment variables from .env file
//dotenv.config();

const MyOrders = () => {
  const [tickets, setTickets] = useState([]);
  const uid = useDataStore((state) => state.uid);
  
  const encryptionKey = "f9dba1fafbb8cce5eccd7e3ebd573d71b7c75d3af1babaccb409f1732a17a493"; // Access the encryption key from the .env file

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const bookedSeatsCollection = collection(db, 'users', uid, 'bookedSeats');
        const querySnapshot = await getDocs(bookedSeatsCollection);
        
        const ticketsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTickets(ticketsData);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    fetchTickets();
  }, [uid]);

  const generateEncryptedQR = (uniqueID, paymentId) => {
    // Combine uniqueID and paymentId
    const combinedData = `${uniqueID}:${paymentId}`;

    // Encrypt the combined string
    const encryptedData = CryptoJS.AES.encrypt(combinedData, encryptionKey).toString();

    return encryptedData;
  };

  const generatePDF = async (ticket, index) => {
    const pdf = new jsPDF();
    const ticketElement = document.getElementById(`ticket-${index}`);
    const downloadButton = ticketElement.querySelector('.download-button');
    
    downloadButton.style.display = 'none';
    await new Promise((resolve) => setTimeout(resolve, 500));
  
    const canvas = await html2canvas(ticketElement, {
      scale: 2,
      useCORS: true,
    });
    const imgData = canvas.toDataURL('image/png');
  
    pdf.addImage(imgData, 'PNG', 10, 10);
    pdf.save(`ticket-${ticket.id}.pdf`);
  
    downloadButton.style.display = 'block';
  };
  
  return (
    <div className="my-orders">
      <h1>My Tickets</h1>
      {tickets.length > 0 ? (
        <div className="tickets-container">
          {tickets.map((ticket, index) => (
            <div key={ticket.id} className="ticket-card" id={`ticket-${index}`}>
              <button className="download-button" onClick={() => generatePDF(ticket, index)}>Download Ticket</button>
              <div style={{display: 'flex', justifyContent: 'space-between', gap: '30px'}}>
                <p>Date: {ticket.date}</p>
                <p>Time: {ticket.time}</p>
              </div>
              <img src={briksha} alt='logo' style={{height: '30px', width: '30px'}} />
              <h3>{ticket.showName}</h3>
              <h3>No: {ticket.seatNumber}</h3>             
              <p>Location: {ticket.location}</p>              
              <p>Mobile No: {ticket.mobile}</p>
              <p style={{paddingBottom: '8px'}}>Payment ID: {ticket.paymentId || 'N/A'}</p>
              
              {/* Generate encrypted QR code */}
              <QRCode className='qrcode' value={generateEncryptedQR(ticket.seatUniqueID, ticket.paymentId || 'NONE')} />
              <p>Code No: {ticket.seatUniqueID}</p>
              <p>Please Collect your tickets from the counter before showtime.</p>
              <p style={{paddingBottom: '20px'}}>অনুগ্ৰহ কৰি সময়ৰ আগতে কাউণ্টাৰৰ পৰা  আপোনাৰ  টিকট সংগ্ৰহ কৰি লব ৷</p>
            </div>
          ))}
        </div>
      ) : (
        <p>No Booked Tickets</p>
      )}

      <MobileMenu />
    </div>
  );
};

export default MyOrders;
