import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, getDoc, getDocs, doc, setDoc, updateDoc, onSnapshot, runTransaction, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import './SeatingArrangement.css';
import useDataStore from '../DataStore';

import logo from '../../logo.svg';
import back from '../../images/back.svg';

const SeatingArrangement = () => {
  const uid = useDataStore((state) => state.uid);
  const { name, email, mobileNumber } = useDataStore();
  const navigate = useNavigate();
  const { id, showType, showId } = useParams();
  const [seats, setSeats] = useState([]);
  const { selectedSeats, setSelectedSeats, totalPrice, setTotalPrice, setGlobalShowType, setGlobalShowId } = useDataStore();
  const [showData, setShowData] = useState([]);
  const [adminData, setAdminData] = useState(null); // State to store admin data
  const [isLoading, setIsLoading] = useState(false); //for loading screen
  const [seatData, setSeatData] = useState([]);
  const amount = totalPrice * 100;
  const currency = "INR";

  useEffect(() => {
    const fetchSeats = async () => {
      const seatsCollection = collection(db, 'theatres', id, showType, showId, 'bookings');
      const seatsSnapshot = await getDocs(seatsCollection);
      const seatsData = seatsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSeats(seatsData);

      const bookedSeatsData = doc(db, 'theatres', id, showType, showId);
      const data = await getDoc(bookedSeatsData);
      setShowData(data.data());
    };

    fetchSeats();
  }, [id, showType, showId]);

  useEffect(() => {
    const seatsCollection = collection(db, 'theatres', id, showType, showId, 'bookings');
    const unsubscribe = onSnapshot(seatsCollection, (snapshot) => {
      const seatsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSeats(seatsData);
    });

    return () => unsubscribe();
  }, [id, showType, showId]);

  //Function to get seat section, number of selected seats
  useEffect(() => {
    const fetchSelectedSeatsData = async () => {
      const seatDataArray = [];
      for (const seatId of selectedSeats) {
        const seatDocRef = doc(db, 'theatres', id, showType, showId, 'bookings', seatId);
        const seatDocSnapshot = await getDoc(seatDocRef);
        if (seatDocSnapshot.exists()) {
          seatDataArray.push({ id: seatId, ...seatDocSnapshot.data() });
        } else {
          console.log(`Document for seat ${seatId} does not exist!`);
        }
      }
      setSeatData(seatDataArray);
    };
    fetchSelectedSeatsData();
  }, [selectedSeats, id, showType, showId]);
  // Function to get seat number
  const getSeatNumber = (seatId) => {
    const seat = seatData.find(seat => seat.id === seatId);
    return seat ? `${seat.section} ${seat.row}${seat.number}` : 'Unknown Seat';
  };
  const getSeatUniqueID = (seatId) => {
    const seat = seatData.find(seat => seat.id === seatId);
    return seat ? `${seat.uniqueID}` : 'Unknown Seat';
  };

  /**
   useEffect(() => {
    const updateSeatsAndPrice = async (uid) => {
      // Create a batch to handle multiple updates
      const batch = writeBatch(db);
      
      // Query for seats with lock equal to uid
      const seatsQuery = query(
        collection(db, 'theatres', id, showType, showId, 'bookings'),
        where('lock', '==', uid)
      );
      
      // Get the query snapshot
      const querySnapshot = await getDocs(seatsQuery);
      
      // Update all seats that match the lock condition
      querySnapshot.forEach((doc) => {
        const seatDocRef = doc.ref; // Reference to the seat document
        batch.update(seatDocRef, { status: 'available', at: null, lock: null });
      });
      
      // Commit the batch update
      await batch.commit();
      
      // Clear selected seats and reset total price
      setSelectedSeats([]);
      setTotalPrice(0);
    };

    const handleBeforeUnload = (event) => {
      if (selectedSeats.length > 0) {
        event.preventDefault();
        event.returnValue = '';
        updateSeatsAndPrice(uid).catch(console.error);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [selectedSeats, setTotalPrice, setSelectedSeats, id, showType, showId, uid]);
   */

  useEffect(() => {
    setGlobalShowType(showType);
    setGlobalShowId(showId);
  }, [showType, showId, setGlobalShowType, setGlobalShowId]);

  // Fetch admin data
  useEffect(() => {
    const fetchAdminData = async () => {
      if (uid) {
        const adminDataCheck = doc(db, 'users', uid);
        const data = await getDoc(adminDataCheck);
        setAdminData(data.data());
      }
    };

    fetchAdminData();
  }, [uid]);

  const handleSeatClick = (seatId, price, status, lock) => {
    // Prevent selection of seats that are already booked or locked
    if (status === 'booked' || lock === 'locked' || status === 'locked') {
      return;
    }
    if (selectedSeats.includes(seatId)) {
      setSelectedSeats(selectedSeats.filter(id => id !== seatId));
      setTotalPrice(totalPrice - price);
    } else {
      setSelectedSeats([...selectedSeats, seatId]);
      setTotalPrice(totalPrice + price);
    }
  };

  const handlePaymentClick = async () => {
    setIsLoading(true);
    try {
      const seatDocRefs = selectedSeats.map(seatId => doc(db, 'theatres', id, showType, showId, 'bookings', seatId));

      await runTransaction(db, async (transaction) => {
        const seats = await Promise.all(seatDocRefs.map(ref => transaction.get(ref)));

        for (const seatDoc of seats) {
          if (!seatDoc.exists()) {
            throw new Error("Seat does not exist!");
          }
          const seatData = seatDoc.data();
          if (seatData.lock && seatData.lock !== uid) {
            setSelectedSeats([]);
            setTotalPrice(0);
            throw new Error("Some seats are no longer available. Please select another seat.");
          }
        }

        seats.forEach(seatDoc => {
          transaction.update(seatDoc.ref, { lock: uid, status: 'locked', at: serverTimestamp() });
        });
      });

      if (adminData?.isAdmin) {
         // Directly confirm booking for admins
        const confirmBooking = window.confirm("Do you want to book these seats?");
        if (confirmBooking) {
          await confirmSeats();
        }
      } else {
        paymentHandler();
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const confirmSeats = async () => {
    try {
      for (const seatId of selectedSeats) {
        const seatDocRef = doc(db, 'theatres', id, showType, showId, 'bookings', seatId);
        await updateDoc(seatDocRef, { status: 'booked', lock: 'locked', mobile: mobileNumber, mode: 'Offline' });
      }

      setTimeout(async () => {
        const bookedSeats = selectedSeats.map(seat => ({
          showName: showData.name,
          image: showData.image,
          location: showData.location,
          date: showData.date,
          time: showData.time,
          seatNumber: getSeatNumber(seat), // Get seat number from seatData
          seatUniqueID: getSeatUniqueID(seat),
          mobile: mobileNumber, 
          ticketId: `${showId}${seat}`,
        }));

        try {
          const userRef = doc(db, 'users', uid);
          const bookedSeatsCollection = collection(userRef, 'bookedSeats');

          for (const seat of bookedSeats) {
            const seatDoc = doc(bookedSeatsCollection, seat.ticketId);
            await setDoc(seatDoc, seat);
          }

          setIsLoading(false);
          setSelectedSeats([]);
          setTotalPrice(0);
          navigate('/myorders');
        } catch (error) {
          console.error('Error saving booked seats:', error);
        }
      }, 1000);
    } catch (error) {
      console.error('Error booking seats:', error);
      alert('An error occurred while booking the seats.');
    }
  };

  const paymentHandler = async () => {
    const response = await fetch("https://us-central1-bookmytheatre-78279.cloudfunctions.net/api/order", {
      method: "POST",
      body: JSON.stringify({
        amount,
        currency,
        receipt: `${showId}_${selectedSeats[0]}_${Date.now()}`,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const order = await response.json();

    var options = {
      "key": "rzp_live_FfFpu3k9JIWEmR",
      amount,
      currency,
      "name": "TheatreDekho",
      "description": "Ticket Booking Transaction",
      "image": logo,
      "order_id": order.id,
      "handler": async function (response) {
        const body = { ...response };

        const validateResponse = await fetch("https://us-central1-bookmytheatre-78279.cloudfunctions.net/api/validate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body)
        });
        const jsonResponse = await validateResponse.json();
        console.log("jsonResponse", jsonResponse);

        for (const seatId of selectedSeats) {
          const seatDocRef = doc(db, 'theatres', id, showType, showId, 'bookings', seatId);
          await updateDoc(seatDocRef, { status: 'booked', lock: 'locked', mobile: mobileNumber, mode: 'Online', paymentId: jsonResponse.paymentId });
        }

        setTimeout(async () => {
          const bookedSeats = selectedSeats.map(seat => ({
            showName: showData.name,
            image: showData.image,
            location: showData.location,
            date: showData.date,
            time: showData.time,
            seatNumber: getSeatNumber(seat), // Get seat number from seatData
            seatUniqueID: getSeatUniqueID(seat),
            mobile: mobileNumber,
            paymentId: `${jsonResponse.paymentId}`,
            ticketId: `${showId}${seat}`,
          }));

          try {
            const userRef = doc(db, 'users', uid);
            const bookedSeatsCollection = collection(userRef, 'bookedSeats');

            for (const seat of bookedSeats) {
              const seatDoc = doc(bookedSeatsCollection, seat.ticketId);
              await setDoc(seatDoc, seat);
            }

            setSelectedSeats([]);
            setTotalPrice(0);
            navigate('/myorders');
          } catch (error) {
            console.error('Error saving booked seats:', error);
          }
        }, 1000);
      },
      "prefill": {
        "name": name,
        "email": email,
        "contact": mobileNumber
      },
      "notes": {
        "address": "Razorpay Corporate Office"
      },
      "theme": {
        "color": "#3399cc"
      }
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      alert(response.error.reason);
    });
    setIsLoading(false);
    rzp1.open();
  };

  const handleNavigateBack = () => {
    navigate(`/${id}`); // This navigates the user back to the previous page
  };

  const renderSeats = () => {
    const sectionOrder = ["VIP", "Season", "Circle", "Special", "Gallery"];
    const groupedSeats = seats.reduce((acc, seat) => {
      if (!acc[seat.section]) acc[seat.section] = {};
      if (!acc[seat.section][seat.row]) acc[seat.section][seat.row] = [];
      acc[seat.section][seat.row].push(seat);
      return acc;
    }, {});

    Object.keys(groupedSeats).forEach(section => {
      Object.keys(groupedSeats[section]).forEach(row => {
        groupedSeats[section][row].sort((a, b) => a.number - b.number);
      });
    });

    return sectionOrder.map((section) => (
      groupedSeats[section] && (
        <div key={section} className="section">
          <h3 className='section-head'>{section}</h3>
          <p className='price'>Price: Rs. {
          // Get all seats in the current section
          seats.filter(seat => seat.section === section)[24]?.price || 'N/A'
          //seats.find(seat => seat.section === section).price
          }</p>
          <div className="rows">
            {Object.keys(groupedSeats[section]).map((row) => (
              <div key={row} className="row-container">
                <div className="row-name">{row}</div>
                <div className="row">
                  {groupedSeats[section][row].map((seat, index) => (
                    <React.Fragment key={seat.id}>
                      {index === 16 || index === 34 ? <div key={`gap-${row}-${index}`} className="gap"></div> : null}
                      <div
                        className={`seat ${seat.status} ${selectedSeats.includes(seat.id) ? 'selected' : ''}`}
                        onClick={() => handleSeatClick(seat.id, seat.price, seat.status, seat.lock)}
                      >
                        <span className="seat-text">{seat.number}</span>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    ));
  };

  return (
    <div className="seating-arrangement">
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
      <button onClick={handleNavigateBack} style={{backgroundColor: 'whitesmoke'}}><img src={back} alt='Back' /></button>
      <h2>{showData.name}</h2>
      </div>
      <div className='seating-arrangement-container'>{renderSeats()}</div>
      {selectedSeats.length > 0 && <div className='paymentbutton'>
        {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div>Confirming your Seats...</div>
        </div>
      )}
        <button className='button1' onClick={handlePaymentClick} disabled={selectedSeats.length === 0}>
          {adminData?.isAdmin ? 'Book Seats' : `Pay Rs. ${totalPrice}`}
        </button>
      </div>}
      <div className="seat-legends-wrapper">
        <div className='available-seat-status'></div>
        <div>Available</div>
        <div className='selected-seat-status'></div>
        <div>Selected</div>
        <div className='sold-seat-status'></div>
        <div>Sold</div>
      </div>
    </div>
  );
};

export default SeatingArrangement;
