// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA_n1dqYufVnlGsP1Ql2UUS750TcRgllWE",
  authDomain: "bookmytheatre-78279.firebaseapp.com",
  projectId: "bookmytheatre-78279",
  storageBucket: "bookmytheatre-78279.appspot.com",
  messagingSenderId: "155348509149",
  appId: "1:155348509149:web:809dcdf203acc310741634",
  measurementId: "G-93MT5YV5FS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
//const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { db, auth, provider, signInWithPopup };