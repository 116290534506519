// Header.js
import React from 'react';

import logo from '.././logo.svg';
import Menu from './Menu';
import './Header.css';

const Header = () => {

  return (
    <header>
      <div className="top-bar">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="search-bar">
          <input type="text" placeholder="Search for theatres, movies, etc." />
        </div>
        <div className="top-bar-right">
          <Menu />
        </div>
      </div>
    </header>
  );
};

export default Header;
