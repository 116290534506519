import React from 'react';
import './ProfilePage.css';
import useDataStore from './DataStore';
import {auth} from '../firebase';
import { useNavigate } from 'react-router-dom';

import './ProfilePage.css';
import MobileMenu from './MobileMenu';

function ProfilePage() {
    const {name, email, photo} = useDataStore();
    const navigate = useNavigate();
    const handleLogout = () => {
        // Implement logout functionality
        auth.signOut();
        navigate('/signin');
    };

  return (
    <div className="profile-page">
      <div className="profile-header">
        <img src={photo} alt="Profile" className="profile-image" />
        <h2 className="profile-name">{name}</h2>
        <p className="profile-email">{email}</p>
      </div>
      <button className="logout-button" onClick={handleLogout}>Logout</button>
      <div className="profile-links">
        <a href="/terms_and_conditions">Terms and Conditions</a>
        <a href="/privacy_policy">Privacy Policies</a>
      </div>
      <MobileMenu/>
    </div>
  );
}

export default ProfilePage;
