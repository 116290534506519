import React, { useState } from 'react';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, message } = formData;
        window.location.href = `mailto:jpboro01@gmail.com?subject=Contact form submission from ${name}&body=Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
    };

    return (
        <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1 style={{ color: '#333' }}>Contact Us</h1>
            <p>We value your feedback and are here to assist you with any questions or concerns. Please feel free to reach out to us using any of the methods below.</p>
            
            <h2 style={{ color: '#333' }}>Email Us</h2>
            <p>You can email us at <a href="mailto:jpboro01@gmail.com" style={{ color: '#007BFF' }}>jpboro01@gmail.com</a>. We strive to respond to all inquiries within 24-48 hours.</p>
            
            <h2 style={{ color: '#333' }}>Call or Text Us</h2>
            <p>You can contact us at +91 7896802376. We strive to respond to all inquiries.</p>

            <h2 style={{ color: '#333' }}>Our Address</h2>
            <p>If you prefer to write to us or visit us in person, our office is located at:</p>
            <p>
                Betna, 781366<br />
                Goreswar, Assam<br />
                India
            </p>
            
            <h2 style={{ color: '#333' }}>Feedback Form</h2>
            <p>Please fill out the form below to send us a message directly:</p>
            <form onSubmit={handleSubmit}>
                <label htmlFor="name" style={{ display: 'block', marginTop: '10px' }}>Name:</label>
                <input 
                    type="text" 
                    id="name" 
                    name="name" 
                    value={formData.name} 
                    onChange={handleChange} 
                    style={{ width: '100%', padding: '10px', marginTop: '5px' }} 
                    required 
                />
                
                <label htmlFor="email" style={{ display: 'block', marginTop: '10px' }}>Email:</label>
                <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleChange} 
                    style={{ width: '100%', padding: '10px', marginTop: '5px' }} 
                    required 
                />
                
                <label htmlFor="message" style={{ display: 'block', marginTop: '10px' }}>Message:</label>
                <textarea 
                    id="message" 
                    name="message" 
                    value={formData.message} 
                    onChange={handleChange} 
                    style={{ width: '100%', padding: '10px', marginTop: '5px', height: '150px' }} 
                    required 
                />
                
                <button type="submit" style={{ marginTop: '10px', padding: '10px 20px', backgroundColor: '#007BFF', color: '#fff', border: 'none', cursor: 'pointer' }}>Send</button>
            </form>
        </div>
    );
};

export default ContactUs;
