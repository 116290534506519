// src/components/Menu.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import './Menu.css';

import { useAuth } from '../useAuth';
import { auth } from '../firebase';
import useDataStore from './DataStore';

const Menu = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const {uid} = useDataStore();
  const [adminData, setAdminData] = useState();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const { currentUser } = useAuth();

  const handleSignOut = () => {
    auth.signOut();
    navigate('/signin');
  };

  useEffect(() => {
    const fetchData = async() => {
        const adminDataCheck = doc(db, 'users', uid);
        const data = await getDoc(adminDataCheck);
        setAdminData(data.data());
    };

    fetchData();
}, [uid])

  return (
    <div className="menu-container">
      <button className="menu-button" onClick={toggleMenu}>Menu</button>
      {isOpen && (
        <div className="menu-content">
          <ul>
            <li><Link to="/">Home</Link></li>
            {adminData && adminData.isAdmin && (<li><Link to="/dashboard">Dashboard</Link></li>)}
            <li><Link to="/myorders">My Orders</Link></li>
            <li><Link to="/profile">Profile</Link></li>
            <li><Link to="/about_us">About</Link></li>
            
            {/* Add more menu items as needed */}
          </ul>
          {currentUser && (
            <button onClick={handleSignOut}>Sign Out</button>
          )}
        </div>
      )}
    </div>
  );
};

export default Menu;
