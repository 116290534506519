// SignIn.js
import React, {useState, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, provider, signInWithPopup, db } from '../firebase';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import './SignIn.css';

import logo from '.././logo.svg';
import google from '../images/google.svg';

import useDataStore from './DataStore';

const SignIn = () => {
  const setUid = useDataStore((state) => state.setUid);
  const {setName, setEmail, setPhoto, mobileNumber, setMobileNumber} = useDataStore();
  const navigate = useNavigate();
  const [isMobileValid, setIsMobileValid] = useState(false);
  const [userMobile, setUserMobile] = useState('');

  useEffect(() => {
    // Clear the mobile number input when the component mounts
    setUserMobile('');
    setIsMobileValid(false);
  }, [setUserMobile]);

  const handleMobileChange = (e) => {
    const value = e.target.value;
    setUserMobile(value);
    // Validate mobile number (assuming 10 digits for this example)
    if (/^\d{10}$/.test(value)) {
      setIsMobileValid(true);
    } else {
      setIsMobileValid(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
    
      // Extract user details
      const uid = user.uid;
      const name = user.displayName;
      const email = user.email;
      const photoURL = user.photoURL;

      // Zustand use
      setUid(uid);
      setName(name);
      setEmail(email);
      setPhoto(photoURL);
      
      setMobileNumber(userMobile);

      // Create or update the user in your database
      await createUserInDatabase(uid, { name, email, mobileNumber, photoURL });
      
      // Clear the mobile number after successful sign-in
      setUserMobile('');
      setIsMobileValid(false);

      navigate('/'); // Redirect to the home page after sign-in
    } catch (error) {
      console.error('Error signing in with Google', error);
    }
  };

  const createUserInDatabase = async (uid, userDetails) => {
    // Implement this function to create or update the user in your Firebase database
    // For example, using Firestore:
    const userDocRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      // If the user already exists, keep the existing isAdmin value
      const existingData = userDoc.data();
      userDetails.isAdmin = existingData.isAdmin || false; // Retain isAdmin status
    } else {
      // If the user does not exist, set isAdmin to the passed value or default to false
      userDetails.isAdmin = userDetails.isAdmin || false;
    }
    await setDoc(userDocRef, userDetails, { merge: true });
  };

  return (
    <div>
      <img src={logo} alt='logo' />
      <div>Book the tickets of your choice</div>
      <h3>Enter mobile number and Click 'Continue with Google' to get started!</h3>
      <div className='contain'>
        <div>Mobile No : 
        <input
        type="text"
        placeholder="Enter mobile number"
        value={userMobile}
        onChange={handleMobileChange}
      />
        </div>
      
      <button className='google-button' onClick={handleGoogleSignIn} disabled={!isMobileValid}>
        <div className='google-wrapper'><img className='google-logo' src={google} alt='google' /></div>
        <div className='social-text'>Continue with Google</div>
      </button>
      </div>
      <div>I agree to the <Link to={'/terms_and_conditions'}>Terms and Conditions</Link> & <Link to={'/privacy_policy'}>Privacy Policy</Link>.</div>
      <div>
          <h1>Welcome to TheatreDekho</h1>
          <p>Book tickets for your awaited play of Awahan Theatre at Goreswar.</p>
          <p>Enter mobile number and Click 'Continue with Google' to get started!</p>
          <h2>Featuring Now: Awahan Theatre 2024-25 at Goreswar</h2>
          <img width="70%" alt='Awahan' src={"https://i.ibb.co/n6vLWmH/awahan.jpg"}/>
          <h3>Don't MISS any one of these:</h3>
          <div className='showsContainer'>
            <div>
              <img className='shows' src='https://i.ibb.co/Kb1Rvgn/awahan1.jpg' alt='Moina Kuxole Asone' />
              <h5>Moina Kuxole Asone</h5>
            </div>
            <div>
              <img className='shows' src='https://i.ibb.co/b6wCN7V/awahan2.jpg' alt='Madam Menoka' />
              <h5>Madam Menoka</h5>
            </div>
            <div>
              <img className='shows' src='https://i.ibb.co/FYkTSTf/awahan3.jpg' alt='Lady Macbeth' />
              <h5>Lady Macbeth</h5>
            </div>
          </div>
          
          <h3>Awahan Theatre Ticket Booking</h3>
          {/* List of popular movies
          abahan theatre 2024-25
          awahan theatre
          abahan theatre 
          abahan theatre owner
          awahan theatre photos*/}
        <div className='contain'>
        <div>Mobile No : 
        <input
        type="text"
        placeholder="Enter mobile number"
        value={userMobile}
        onChange={handleMobileChange}
      />
        </div>
          <button className='google-button' onClick={handleGoogleSignIn} disabled={!isMobileValid}>
            <div className='google-wrapper'><img className='google-logo' src={google} alt='google' /></div>
            <div className='social-text'>Continue with Google</div>
          </button>
        </div>
          <h2>Theatres Near You</h2>
          <p>Abahan Theatre</p>
          {/* List of theatres */}
          <h3>Why Choose TheatreDekho?</h3>
          <ul>
            <li>Easy online booking</li>
            <li>Wide range of theatres</li>
            <li>Exclusive offers and discounts</li>
          </ul>
      </div>
      <div><Link to={'/contact_us'}>Contact Us</Link>: 24X7 Support</div>
      <div><Link to={'/cancellation_and_refund'}>Return & Refund Policy</Link>: No cancellations & Refunds are entertained</div>
      <div>Shipping and Delivery: Shipping is not applicable for business.</div>
      <p>Note: This website is online ticketing platform for Awahan Theatre 2024 to be held at Goreswar. Book tickets only from here or at the ticket counter. There is no other site for online booking.</p>
    </div>
  );
};

export default SignIn;
